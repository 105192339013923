<template>
  <div class="develop">
    <div class="mask"></div>
    <div style="position: relative">
      <img class="img" :src="require('@/assets/logo_big.png')" />
      <h2 class="title">Coming soon...</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'develop'
}
</script>

<style lang="scss" scoped>
.develop {
  position: relative;
  padding-top: 7rem;
  padding-bottom: 15.25rem;
  text-align: center;
  background: url("../assets/banner.png") no-repeat;
  color: #ffac15;

  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(8, 16, 22, 0.9);
  }

  .title {
    margin-top: 2.8rem;
    font-size: 28px;
  }
}
</style>
